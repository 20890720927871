
pre,
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  background-color: var(--gray-color);
  border-radius: 0.2em;
}
code {
  margin: 0;
  padding: 0.2em 0.4em;
  font-size: 75%;
}

pre {
  padding: 1em;
  overflow: auto;
}

pre code {
  line-height: 1.45;
}
