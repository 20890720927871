body {
  margin: 0;
  padding: 0;
}

body,
p,
ol,
ul {
  font-size: 1em;
}
