:root {
  --open-sans-fea-aalt: "aalt" off;
  --open-sans-fea-dnom: "dnom" off;
  --open-sans-fea-frac: "frac" off;
  --open-sans-fea-liga: "liga" on;
  --open-sans-fea-lnum: "lnum" off;
  --open-sans-fea-locl: "locl" on;
  --open-sans-fea-numr: "numr" off;
  --open-sans-fea-onum: "onum" on;
  --open-sans-fea-ordn: "ordn" off;
  --open-sans-fea-pnum: "pnum" on;
  --open-sans-fea-salt: "salt" off;
  --open-sans-fea-ss01: "ss01" off;
  --open-sans-fea-ss02: "ss02" off;
  --open-sans-fea-ss03: "ss03" off;
  --open-sans-fea-subs: "subs" off;
  --open-sans-fea-sups: "sups" off;
  --open-sans-fea-tnum: "tnum" off;
  --open-sans-fea-zero: "zero" off;
}

html {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  font-feature-settings: var(--open-sans-fea-aalt), var(--open-sans-fea-dnom),
    var(--open-sans-fea-frac), var(--open-sans-fea-liga),
    var(--open-sans-fea-lnum), var(--open-sans-fea-locl),
    var(--open-sans-fea-numr), var(--open-sans-fea-onum),
    var(--open-sans-fea-ordn), var(--open-sans-fea-pnum),
    var(--open-sans-fea-salt), var(--open-sans-fea-ss01),
    var(--open-sans-fea-ss02), var(--open-sans-fea-ss03),
    var(--open-sans-fea-subs), var(--open-sans-fea-sups),
    var(--open-sans-fea-tnum), var(--open-sans-fea-zero);

  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
}

@media screen and (min-width: 37rem) {
  html {
    font-size: 17px;
  }
}

@media screen and (min-width: 60rem) {
  html {
    font-size: 20px;
  }
}

@media print {
  html {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    font-size: 10pt;
  }
}
