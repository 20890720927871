/* reset css */

/* critical:start */

/* inspiration
- https://alligator.io/css/minimal-css-reset/
*/

:root {
  --green-color: #006984; /* c: 100 m: 0 y: 20 k: 65 */
  --blue-color: #0054a2; /* c: 100 m: 70 y: 10 k: 52 */
  --gray-color: #e6e8ea;
  --brown-color: rgb(178, 112, 0);
  --gray-red: #f00; /* c: 0 m: 100 y: 100 k: 0 */
}

::-moz-selection {
  background: #0054a2;
  background: var(--blue-color);
  color: white;
  text-shadow: none;
}

::selection {
  background: #0054a2;
  background: var(--blue-color);
  color: white;
  text-shadow: none;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

:root {
  --open-sans-fea-aalt: "aalt" off;
  --open-sans-fea-dnom: "dnom" off;
  --open-sans-fea-frac: "frac" off;
  --open-sans-fea-liga: "liga" on;
  --open-sans-fea-lnum: "lnum" off;
  --open-sans-fea-locl: "locl" on;
  --open-sans-fea-numr: "numr" off;
  --open-sans-fea-onum: "onum" on;
  --open-sans-fea-ordn: "ordn" off;
  --open-sans-fea-pnum: "pnum" on;
  --open-sans-fea-salt: "salt" off;
  --open-sans-fea-ss01: "ss01" off;
  --open-sans-fea-ss02: "ss02" off;
  --open-sans-fea-ss03: "ss03" off;
  --open-sans-fea-subs: "subs" off;
  --open-sans-fea-sups: "sups" off;
  --open-sans-fea-tnum: "tnum" off;
  --open-sans-fea-zero: "zero" off;
}

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  -webkit-font-feature-settings: "aalt" off, "dnom" off,
    "frac" off, "liga" on,
    "lnum" off, "locl" on,
    "numr" off, "onum" on,
    "ordn" off, "pnum" on,
    "salt" off, "ss01" off,
    "ss02" off, "ss03" off,
    "subs" off, "sups" off,
    "tnum" off, "zero" off;
          font-feature-settings: "aalt" off, "dnom" off,
    "frac" off, "liga" on,
    "lnum" off, "locl" on,
    "numr" off, "onum" on,
    "ordn" off, "pnum" on,
    "salt" off, "ss01" off,
    "ss02" off, "ss03" off,
    "subs" off, "sups" off,
    "tnum" off, "zero" off;
  -webkit-font-feature-settings: var(--open-sans-fea-aalt), var(--open-sans-fea-dnom),
    var(--open-sans-fea-frac), var(--open-sans-fea-liga),
    var(--open-sans-fea-lnum), var(--open-sans-fea-locl),
    var(--open-sans-fea-numr), var(--open-sans-fea-onum),
    var(--open-sans-fea-ordn), var(--open-sans-fea-pnum),
    var(--open-sans-fea-salt), var(--open-sans-fea-ss01),
    var(--open-sans-fea-ss02), var(--open-sans-fea-ss03),
    var(--open-sans-fea-subs), var(--open-sans-fea-sups),
    var(--open-sans-fea-tnum), var(--open-sans-fea-zero);
          font-feature-settings: var(--open-sans-fea-aalt), var(--open-sans-fea-dnom),
    var(--open-sans-fea-frac), var(--open-sans-fea-liga),
    var(--open-sans-fea-lnum), var(--open-sans-fea-locl),
    var(--open-sans-fea-numr), var(--open-sans-fea-onum),
    var(--open-sans-fea-ordn), var(--open-sans-fea-pnum),
    var(--open-sans-fea-salt), var(--open-sans-fea-ss01),
    var(--open-sans-fea-ss02), var(--open-sans-fea-ss03),
    var(--open-sans-fea-subs), var(--open-sans-fea-sups),
    var(--open-sans-fea-tnum), var(--open-sans-fea-zero);

  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
}

body {
  margin: 0;
  padding: 0;
}

body,
p,
ol,
ul {
  font-size: 1em;
}

p {
  margin-top: 0;
  margin-bottom: 0.3em;
}

.next-bg + p {
  padding: 1em;
  background-color: #e6e8ea;
  background-color: var(--gray-color);
}

hr {
  border: none;
  height: 2px;
  background-color: #222;

  margin-top: 2.2rem;
  margin-bottom: 2.4rem;
}

hr + h2:before, hr + h3:before, hr + h4:before {
    content: "";
    margin-bottom: -1.7rem;
    display: table;
  }

hr.thick {
  height: 1rem;
}

ul,
ol {
  padding-left: 1em;
  margin: 0;
  margin-top: 1em;
  margin-bottom: 1em;
}

ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
  }

/* List direct after title */

h1 + ul, h1 + ol,
h2 + ul, h2 + ol,
h3 + ul, h3 + ol,
h4 + ul, h4 + ol,
h5 + ul, h5 + ol,
h6 + ul, h6 + ol {
  margin-top: 0.25em;
}

li {
  /* same as p bottom */
  margin-bottom: 0.3em;
}

ul {
  list-style: none;
}

ul > li::before {
  content: "\2013\A0";
  color: #0054a2;
  color: var(--blue-color);
  display: inline-block;
  width: 1em;
  margin-left: -1em;

}

ol {
  counter-reset: ol-li;
  list-style: none;
  padding-left: 1.4em;
}

ol > li > p {
  display: inline;
}

ol > li::before {
  content: counter(ol-li) ".";
  counter-increment: ol-li;

  display: inline-block;
  width: 1.4em;
  margin-left: -1.4em;


  text-align: center;
  margin-right: 0.35em;
}

/* ol {
  counter-reset: ol-li;
  padding-left: 0;
}

ol > li {
  position: relative;
  margin: 0 0 0 1.75em;
  list-style: none;
  padding-bottom: 0.2em;
}

ol > li:before {
  content: counter(ol-li);
  counter-increment: ol-li;

  position: absolute;
  top: 0.1em;

  left: -1.75em;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;

  font-size: 0.85em;
  font-weight: 700;
  text-align: center;

  color: white;
  background: var(--blue-color);
} */

a {
    text-decoration: none;
    color: #006984;
    color: var(--green-color);
}

a:visited {
    color: #0054a2;
    color: var(--blue-color);
}

a:hover {
    color: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

img {
  margin: 1em 0;
}

blockquote {
  font-weight: 700;
  color: #006984;
  color: var(--green-color);
  line-height: 1.7;
  margin-top: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  margin-left: 2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin-top: 1.4em;
  margin-bottom: 0.3em;
}

h1 {
  font-size: 1.2rem;
  margin-bottom: 0.8em;

  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* deactivate Oldstyle figures and ligatures for uppercases */
  --open-sans-fea-liga: "liga" off;
  --open-sans-fea-lnum: "lnum" on;
  --open-sans-fea-onum: "onum" off;

  -webkit-font-feature-settings: "aalt" off, "dnom" off,
    "frac" off, "liga" on,
    "lnum" off, "locl" on,
    "numr" off, "onum" on,
    "ordn" off, "pnum" on,
    "salt" off, "ss01" off,
    "ss02" off, "ss03" off,
    "subs" off, "sups" off,
    "tnum" off, "zero" off;

          font-feature-settings: "aalt" off, "dnom" off,
    "frac" off, "liga" on,
    "lnum" off, "locl" on,
    "numr" off, "onum" on,
    "ordn" off, "pnum" on,
    "salt" off, "ss01" off,
    "ss02" off, "ss03" off,
    "subs" off, "sups" off,
    "tnum" off, "zero" off;

  -webkit-font-feature-settings: var(--open-sans-fea-aalt), var(--open-sans-fea-dnom),
    var(--open-sans-fea-frac), var(--open-sans-fea-liga),
    var(--open-sans-fea-lnum), var(--open-sans-fea-locl),
    var(--open-sans-fea-numr), var(--open-sans-fea-onum),
    var(--open-sans-fea-ordn), var(--open-sans-fea-pnum),
    var(--open-sans-fea-salt), var(--open-sans-fea-ss01),
    var(--open-sans-fea-ss02), var(--open-sans-fea-ss03),
    var(--open-sans-fea-subs), var(--open-sans-fea-sups),
    var(--open-sans-fea-tnum), var(--open-sans-fea-zero);

          font-feature-settings: var(--open-sans-fea-aalt), var(--open-sans-fea-dnom),
    var(--open-sans-fea-frac), var(--open-sans-fea-liga),
    var(--open-sans-fea-lnum), var(--open-sans-fea-locl),
    var(--open-sans-fea-numr), var(--open-sans-fea-onum),
    var(--open-sans-fea-ordn), var(--open-sans-fea-pnum),
    var(--open-sans-fea-salt), var(--open-sans-fea-ss01),
    var(--open-sans-fea-ss02), var(--open-sans-fea-ss03),
    var(--open-sans-fea-subs), var(--open-sans-fea-sups),
    var(--open-sans-fea-tnum), var(--open-sans-fea-zero);
}

h2 {
  font-size: 1.15rem;
  margin-top: 1.6em;
  margin-bottom: 0.4em;
}

h3 {
  font-size: 1rem;
}

pre,
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  background-color: #e6e8ea;
  background-color: var(--gray-color);
  border-radius: 0.2em;
}

code {
  margin: 0;
  padding: 0.2em 0.4em;
  font-size: 75%;
}

pre {
  padding: 1em;
  overflow: auto;
}

pre code {
  line-height: 1.45;
}

.content {
  width: 92%;
  max-width: 36rem;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0;
  padding-bottom: 2rem;
}

.grid-12col {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.col-1to6,
.col-7to12 {
  max-width: 48.5%;
  -ms-flex-preferred-size: 48.5%;
      flex-basis: 48.5%;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.col-1to4 {
  max-width: 32.333%;
  -ms-flex-preferred-size: 32.333%;
      flex-basis: 32.333%;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.col-5to12 {
  max-width: 64.666%;
  -ms-flex-preferred-size: 64.666%;
      flex-basis: 64.666%;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.col-all {
  max-width: 100%;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

article {
  margin: 0;
}

article > header {
  margin-top: 2rem;
  padding-bottom: 2rem;
}

article > header address,
article > header .small--first {
  font-style: normal;
  margin-top: 4.5rem;
}

article > section,
article > footer {
  padding-bottom: 2.2rem;
  border-top: 2px solid #222;
}

article > section > h2:first-child,
article > footer > h2:first-child {
  margin-top: 0.7em;
}

article .small {
  font-size: 0.8rem;
}

em {
  font-style: italic;
}

strong,
b {
  font-style: normal;
  font-weight: 700;
}

small,
.small {
  font-size: 0.8em;
}

/* critical:end */

table {
  width: 100%;
  font-size: 0.9rem;
  border-collapse: collapse;

  margin-top: 1em;
  margin-bottom: 1em;
}

th {
  text-align: left;
}

tr {
  border-bottom: 1px solid #e6e8ea;
  border-bottom: 1px solid var(--gray-color);
}

td,
th {
  vertical-align: top;
  padding-right: 1rem;

  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

td:last-child,
th:last-child {
  padding-right: 0;
}

table {
  --open-sans-fea-pnum: "pnum" off;
  --open-sans-fea-lnum: "lnum" on;

  -webkit-font-feature-settings: "aalt" off, "dnom" off,
    "frac" off, "liga" on,
    "lnum" off, "locl" on,
    "numr" off, "onum" on,
    "ordn" off, "pnum" on,
    "salt" off, "ss01" off,
    "ss02" off, "ss03" off,
    "subs" off, "sups" off,
    "tnum" off, "zero" off;

          font-feature-settings: "aalt" off, "dnom" off,
    "frac" off, "liga" on,
    "lnum" off, "locl" on,
    "numr" off, "onum" on,
    "ordn" off, "pnum" on,
    "salt" off, "ss01" off,
    "ss02" off, "ss03" off,
    "subs" off, "sups" off,
    "tnum" off, "zero" off;

  -webkit-font-feature-settings: var(--open-sans-fea-aalt), var(--open-sans-fea-dnom),
    var(--open-sans-fea-frac), var(--open-sans-fea-liga),
    var(--open-sans-fea-lnum), var(--open-sans-fea-locl),
    var(--open-sans-fea-numr), var(--open-sans-fea-onum),
    var(--open-sans-fea-ordn), var(--open-sans-fea-pnum),
    var(--open-sans-fea-salt), var(--open-sans-fea-ss01),
    var(--open-sans-fea-ss02), var(--open-sans-fea-ss03),
    var(--open-sans-fea-subs), var(--open-sans-fea-sups),
    var(--open-sans-fea-tnum), var(--open-sans-fea-zero);

          font-feature-settings: var(--open-sans-fea-aalt), var(--open-sans-fea-dnom),
    var(--open-sans-fea-frac), var(--open-sans-fea-liga),
    var(--open-sans-fea-lnum), var(--open-sans-fea-locl),
    var(--open-sans-fea-numr), var(--open-sans-fea-onum),
    var(--open-sans-fea-ordn), var(--open-sans-fea-pnum),
    var(--open-sans-fea-salt), var(--open-sans-fea-ss01),
    var(--open-sans-fea-ss02), var(--open-sans-fea-ss03),
    var(--open-sans-fea-subs), var(--open-sans-fea-sups),
    var(--open-sans-fea-tnum), var(--open-sans-fea-zero);
}

@media screen and (min-width: 37rem) {
  html {
    font-size: 17px;
  }
}

@media screen and (min-width: 60rem) {
  html {
    font-size: 20px;
  }
}

@media print {
  html {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    font-size: 10pt;
  }
  a[href^="http"]::after {
    content: " (" attr(href) ")";
  }
  .content {
    margin-right: 0;
  }

    @page {
        size: A4;
        margin: 5mm 10mm 7mm 25mm;
    }


    .content {
        display: block;
        page-break-before: always;
        counter-increment: page;
        right: 0;
        top: 0;
        width: 100%;
        height: 1.5rem;
        border-bottom: 0.05rem solid black;
        margin-bottom: 3.5rem
    }

        .content:after {
            display: inline-block;
            padding-top: 0.4em;
            content: "Signalwerk GmbH \B7   Stefan Huber \B7   CC BY-SA 4.0";
            float: left;
            white-space: nowrap;
            text-align: left;
            position: absolute;
        }

        .content:before {
            display: inline-block;
            padding-top: 0.0em;
            content: counter(page);
            content: " " counter(page);
            float: right;
            white-space: nowrap;
        }
  tr {
    page-break-inside: avoid;
    border-bottom: 0.1rem solid #e6e8ea;
    border-bottom: 0.1rem solid var(--gray-color);
  }
}


/*# sourceMappingURL=main.css.map*/