ul,
ol {
  padding-left: 1em;
  margin: 0;
  margin-top: 1em;
  margin-bottom: 1em;

  ul,
  ol {
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
  }
}


/* List direct after title */
h1 + ul, h1 + ol,
h2 + ul, h2 + ol,
h3 + ul, h3 + ol,
h4 + ul, h4 + ol,
h5 + ul, h5 + ol,
h6 + ul, h6 + ol {
  margin-top: 0.25em;
}


li {
  /* same as p bottom */
  margin-bottom: 0.3em;
}

ul {
  list-style: none;
}

ul > li::before {
  content: "–\00A0";
  color: var(--blue-color);
  display: inline-block;
  width: 1em;
  margin-left: -1em;

}

ol {
  counter-reset: ol-li;
  list-style: none;
  padding-left: 1.4em;
}

ol > li > p {
  display: inline;
}

ol > li::before {
  content: counter(ol-li) ".";
  counter-increment: ol-li;

  display: inline-block;
  width: 1.4em;
  margin-left: -1.4em;


  text-align: center;
  margin-right: 0.35em;
}

/* ol {
  counter-reset: ol-li;
  padding-left: 0;
}

ol > li {
  position: relative;
  margin: 0 0 0 1.75em;
  list-style: none;
  padding-bottom: 0.2em;
}

ol > li:before {
  content: counter(ol-li);
  counter-increment: ol-li;

  position: absolute;
  top: 0.1em;

  left: -1.75em;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;

  font-size: 0.85em;
  font-weight: 700;
  text-align: center;

  color: white;
  background: var(--blue-color);
} */
