
a {
    text-decoration: none;
    color: var(--green-color);
}

a:visited {
    color: var(--blue-color);
}

a:hover {
    color: inherit;
}

@media print {
  a[href^="http"]::after {
    content: " (" attr(href) ")";
  }
}
