/* inspiration
- https://alligator.io/css/minimal-css-reset/
*/

:root {
  --green-color: #006984; /* c: 100 m: 0 y: 20 k: 65 */
  --blue-color: #0054a2; /* c: 100 m: 70 y: 10 k: 52 */
  --gray-color: #e6e8ea;
  --brown-color: rgb(178, 112, 0);
  --gray-red: #f00; /* c: 0 m: 100 y: 100 k: 0 */
}

::selection {
  background: var(--blue-color);
  color: white;
  text-shadow: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
