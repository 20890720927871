article {
  margin: 0;
}
article > header {
  margin-top: 2rem;
  padding-bottom: 2rem;
}

article > header address,
article > header .small--first {
  font-style: normal;
  margin-top: 4.5rem;
}

article > section,
article > footer {
  padding-bottom: 2.2rem;
  border-top: 2px solid #222;
}

article > section > h2:first-child,
article > footer > h2:first-child {
  margin-top: 0.7em;
}

article .small {
  font-size: 0.8rem;
}
