.content {
  width: 92%;
  max-width: 36rem;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0;
  padding-bottom: 2rem;
}

@media print {
  .content {
    margin-right: 0;
  }
}

.grid-12col {
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
}

.col-1to6,
.col-7to12 {
  max-width: 48.5%;
  flex-basis: 48.5%;
  flex-shrink: 1;
  flex-grow: 1;
}

.col-1to4 {
  max-width: 32.333%;
  flex-basis: 32.333%;
  flex-shrink: 1;
  flex-grow: 1;
}

.col-5to12 {
  max-width: 64.666%;
  flex-basis: 64.666%;
  flex-shrink: 1;
  flex-grow: 1;
}

.col-all {
  max-width: 100%;
  flex-basis: 100%;
  flex-shrink: 1;
  flex-grow: 1;
}
