h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin-top: 1.4em;
  margin-bottom: 0.3em;
}

h1 {
  font-size: 1.2rem;
  margin-bottom: 0.8em;

  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* deactivate Oldstyle figures and ligatures for uppercases */
  --open-sans-fea-liga: "liga" off;
  --open-sans-fea-lnum: "lnum" on;
  --open-sans-fea-onum: "onum" off;

  font-feature-settings: var(--open-sans-fea-aalt), var(--open-sans-fea-dnom),
    var(--open-sans-fea-frac), var(--open-sans-fea-liga),
    var(--open-sans-fea-lnum), var(--open-sans-fea-locl),
    var(--open-sans-fea-numr), var(--open-sans-fea-onum),
    var(--open-sans-fea-ordn), var(--open-sans-fea-pnum),
    var(--open-sans-fea-salt), var(--open-sans-fea-ss01),
    var(--open-sans-fea-ss02), var(--open-sans-fea-ss03),
    var(--open-sans-fea-subs), var(--open-sans-fea-sups),
    var(--open-sans-fea-tnum), var(--open-sans-fea-zero);
}

h2 {
  font-size: 1.15rem;
  margin-top: 1.6em;
  margin-bottom: 0.4em;
}

h3 {
  font-size: 1rem;
}
