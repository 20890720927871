hr {
  border: none;
  height: 2px;
  background-color: #222;

  margin-top: 2.2rem;
  margin-bottom: 2.4rem;
}

hr + h2,
hr + h3,
hr + h4 {
  &:before {
    content: "";
    margin-bottom: -1.7rem;
    display: table;
  }
}

hr.thick {
  height: 1rem;
}
